var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-card',{staticStyle:{"margin":"20px 0"},attrs:{"body-style":{ paddingBottom: 0 }}},[_c('form-create',{attrs:{"components":[
        { name: 'form-item', props: { prop: 'keyword', label: '作业名称' } },
        // {
        //   name: 'form-item',
        //   props: {
        //     prop: 'valid',
        //     label: '是否有效',
        //     type: 'select',
        //     data: [
        //       { label: '有效', value: 1 },
        //       { label: '无效', value: 2 },
        //     ],
        //   },
        // },
        {
          name: 'form-item',
          props: {
            prop: 'unit_id',
            label: '章节',
            type: 'select',
            data: {
              url: '/xapi/course.course/unitOptions',
              params: { ..._vm.$route.params },
            },
          },
        },
      ],"inline":"","label-width":"80px"},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}},[_c('el-form-item',{staticClass:"operate",attrs:{"slot":"after"},slot:"after"},[_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){return _vm.$refs.table.reset(_vm.where)}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":function($event){(_vm.where = { }) && _vm.$refs.table.reset(_vm.where)}}},[_vm._v("重置")])],1)],1)],1),_c('div',{staticClass:"operate",staticStyle:{"margin-bottom":"15px"}},[_c('el-button-group',[_c('el-button',{attrs:{"type":"primary","size":"medium","disabled":!_vm.$isPowers((_vm.priexRoute || 'course') + 'TaskCreate')},on:{"click":function($event){_vm.$handleRoute(
            { course_list_id: _vm.$route.query.list_id },
            (_vm.priexRoute || 'teachingCourse') + 'TaskCreate'
          )}}},[_vm._v("创建作业")])],1)],1),_c('el-card',[_c('com-table',{ref:"table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"where":_vm.where,"edit-method":_vm.handleEditChange},scopedSlots:_vm._u([{key:"action",fn:function(scope){return [_c('el-button-group',[_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){_vm.$handleRoute(
                { other_id: scope.row.id,group_id: scope.row.group_id },
                (_vm.priexRoute || 'teachingCourse') + 'TaskInfo'
              )}}},[_vm._v("做作业")]),_c('el-button',{attrs:{"type":"text","size":"small","disabled":!_vm.$isPowers((_vm.priexRoute || 'course') + 'TaskEdit')},on:{"click":function($event){_vm.$handleRoute(
                { other_id: scope.row.id },
                (_vm.priexRoute || 'teachingCourse') + 'TaskEdit'
              )}}},[_vm._v("编辑")]),(_vm.priexRoute !== 'teachPlanCourse')?_c('el-button',{attrs:{"type":"text","size":"small","disabled":!_vm.$isPowers((_vm.priexRoute || 'course') + 'TaskCensus')},on:{"click":function($event){_vm.$handleRoute(
                { other_id: scope.row.id,group_id: scope.row.group_id,course_id:_vm.course_id},
                (_vm.priexRoute || 'teachingCourse') + 'TaskCensus'
              )}}},[_vm._v("答题统计")]):_vm._e(),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){_vm.$handleRoute(
                { other_id: scope.row.id },
                (_vm.priexRoute || 'teachingCourse') + 'TaskQuestion'
              )}}},[_vm._v("题目管理")]),_c('el-button',{attrs:{"disabled":!_vm.$isPowers((_vm.priexRoute || 'course') + 'TaskDel'),"type":"text","size":"small"},on:{"click":function($event){_vm.$handleConfirm(
                '你确定删除此条作业信息吗？',
                '/xapi/question.other/delete',
                { ..._vm.$route.params, id: scope.row.id },
                () => {
                  _vm.$refs.table.reload();
                }
              )}}},[_vm._v("删除")])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }