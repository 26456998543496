<template>
  <div>
    <el-card :body-style="{ paddingBottom: 0 }" style="margin: 20px 0">
      <form-create
        v-model="where"
        :components="[
          { name: 'form-item', props: { prop: 'keyword', label: '作业名称' } },
          // {
          //   name: 'form-item',
          //   props: {
          //     prop: 'valid',
          //     label: '是否有效',
          //     type: 'select',
          //     data: [
          //       { label: '有效', value: 1 },
          //       { label: '无效', value: 2 },
          //     ],
          //   },
          // },
          {
            name: 'form-item',
            props: {
              prop: 'unit_id',
              label: '章节',
              type: 'select',
              data: {
                url: '/xapi/course.course/unitOptions',
                params: { ...$route.params },
              },
            },
          },
        ]"
        inline
        label-width="80px"
      >
        <el-form-item class="operate" slot="after">
          <el-button
            type="primary"
            size="medium"
            @click="$refs.table.reset(where)"
            >查询</el-button
          >
          <!-- <el-button
            type="primary"
            size="medium"
            @click="(where = { valid: 1 }) && $refs.table.reset(where)"
            >重置</el-button
          > -->
          <el-button
            type="primary"
            size="medium"
            @click="(where = { }) && $refs.table.reset(where)"
            >重置</el-button
          >
        </el-form-item>
      </form-create>
    </el-card>
    <div class="operate" style="margin-bottom: 15px">
      <el-button-group>
        <el-button
          type="primary"
          size="medium"
          @click="
            $handleRoute(
              { course_list_id: $route.query.list_id },
              (priexRoute || 'teachingCourse') + 'TaskCreate'
            )
          "
          :disabled="!$isPowers((priexRoute || 'course') + 'TaskCreate')"
          >创建作业</el-button
        >
      </el-button-group>
    </div>
    <el-card>
      <com-table
        ref="table"
        :data="tableData"
        :columns="columns"
        :where="where"
        :edit-method="handleEditChange"
      >
        <template slot="action" slot-scope="scope" >
          <el-button-group>
            <el-button
              type="text"
              size="small"
              @click="
                $handleRoute(
                  { other_id: scope.row.id,group_id: scope.row.group_id },
                  (priexRoute || 'teachingCourse') + 'TaskInfo'
                )
              "
              >做作业</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="!$isPowers((priexRoute || 'course') + 'TaskEdit')"
              @click="
                $handleRoute(
                  { other_id: scope.row.id },
                  (priexRoute || 'teachingCourse') + 'TaskEdit'
                )
              "
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              :disabled="!$isPowers((priexRoute || 'course') + 'TaskCensus')"
              @click="
                $handleRoute(
                  { other_id: scope.row.id,group_id: scope.row.group_id,course_id:course_id},
                  (priexRoute || 'teachingCourse') + 'TaskCensus'
                )
              "
              v-if="priexRoute !== 'teachPlanCourse'"
              >答题统计</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="
                $handleRoute(
                  { other_id: scope.row.id },
                  (priexRoute || 'teachingCourse') + 'TaskQuestion'
                )
              "
              >题目管理</el-button
            >
            <el-button
              :disabled="!$isPowers((priexRoute || 'course') + 'TaskDel')"
              @click="
                $handleConfirm(
                  '你确定删除此条作业信息吗？',
                  '/xapi/question.other/delete',
                  { ...$route.params, id: scope.row.id },
                  () => {
                    $refs.table.reload();
                  }
                )
              "
              type="text"
              size="small"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </com-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["priexRoute", "course_id"],
  data() {
    var _this = this;
    return {
      where: {},
      columns: [
        {
          prop: "status",
          label: "状态",
          width: "120",
          align: "center",
          template: ({ row }) => {
            return (
              { "-1": "已结束", 0: "未开始", 1: "正在进行" }[row.status] || "-"
            );
          },
          hidden: _this.priexRoute === "teachPlanCourse",
        },
        { prop: "unit_name", label: "章节名称", width: "180" },
        { prop: "list_name", label: "条目名称", width: "180" },
        { prop: "title", label: "作业名称", width: "180" },
        // { prop: "activ_time", label: "时间", width: "240", align: "center" },
        { prop: "teach_name", label: "创建人", align: "center" },
        // {
        //   prop: "valid",
        //   label: "是否有效",
        //   width: "120",
        //   slot: "switch",
        //   align: "center",
        // },
        {
          prop: "is_publish",
          label: "是否布置",
          width: "120",
          slot: "switch",
          align: "center",
          hidden: _this.priexRoute === "teachPlanCourse",
        },
        {
          prop: "create_time",
          label: "创建时间",
          width: "160",
          align: "center",
        },
        {
          fixed: "right",
          label: "操作",
          width: "240",
          slot: "action",
          align: "center",
        },
      ],
      tableData: {
        // url: "/xapi/question.other/taskList",
        url: "/xapi/question.question_other/taskList",
      },
    };
  },
  created(){
    if(this.priexRoute !== 'teachPlanCourse'){
      console.log("进入作业列表");
      localStorage.setItem('isCourse', 1)
    }
  },
  methods: {
  
    handleEditChange(v, ov, { row, column }) {
      console.log(v,"vvv");
      console.log(ov,"ovov");
      console.log(row,"rowrow");
      console.log(column,"columncolumn");
      this.$http
        .request({
          url: "/xapi/question.other/updateField",
          params: {
            field: column.prop,
            id: row.id,
            value: v,
          },
        })
        .catch(() => {
          row[column.prop] = ov;
        });
    },
  },
};
</script>

<style scoped>
</style>